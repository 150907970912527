<template>

	<div class="panQxzt">		
    	<QXZT />
	</div>

	<div class="panKqzs">		
    	<KQZS />
	</div>
	
	<!-- <div class="panHjzs">		
    	<HJZS />
	</div> -->
	
	<!-- <div class="panSzjc">		
    	<SZJC />
	</div> -->

	<div class="panmap">
		<!-- <div class="nav">
			<ul>
				<li><p index=1>地理优势</p></li>
				<li><p index=2>航空优势</p></li>
				<li><p index=0>交通优势</p></li>
				<li><p index=3>经济优势</p></li>
			</ul>
		</div> -->

		<div class="mapleft"></div>
		<div class="maptop"></div>
		
		<div class="map">
    		<MapQWYS />
		</div>

		<div class="mapright"></div>
		<div class="mapbottom"></div>
	</div>

	<div>		
    	<DIALOG_INFO ref="child_dialog_info" />
	</div>

	<div class="panSxlc">		
    	<SXLC />
	</div>
	
	<!-- <div class="panKygk">		
    	<KYGK />
	</div> -->
	
	<!-- <div class="panYsll">		
    	<YSLL />
	</div> -->
	
	<!-- <div class="panJtgjll">		
    	<JTGJLL />
	</div> -->

</template>


<script>
import QXZT from '@/components/qwys/qxzt.vue'
import KQZS from '@/components/qwys/kqzs.vue'
import HJZS from '@/components/qwys/hjzs.vue'
import SZJC from '@/components/qwys/szjc.vue'
import SXLC from '@/components/qwys/sxlc.vue'
import KYGK from '@/components/qwys/kygk.vue'
import YSLL from '@/components/qwys/ysll.vue'
import JTGJLL from '@/components/qwys/jtgjll.vue'
import MapApi from '@/components/MapApi.vue'
import MapQWYS from '@/components/map/MapQwys.vue'
import DIALOG_INFO from '@/components/dialog/dialog_info.vue'

export default {

  components: {
    QXZT,
	KQZS,
	HJZS,
	SZJC,
	SXLC,
	KYGK,
	YSLL,
	JTGJLL,	

    MapApi,
	MapQWYS,

	DIALOG_INFO,
  },

	methods: {

		openInfo(target, distance) {
			this.$refs.child_dialog_info.open(target, distance);
		},

	}
}
</script>


<style scoped>

.panmap {		
	top: 0px;
	left: 582px;
	width: 1465px;
	height: 945px;
	text-align: center;
  	position:relative;	
  	overflow: hidden;
}

.panQxzt {	
	top: 79px;
	left: 30px;
	width: 554px;
	height: 549px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}

.panKqzs {	
	top: 643px;
	left: 30px;
	width: 554px;
	height: 273px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}

.panHjzs {	
	top: 79px;
	left: 599px;
	width: 552px;
	height: 411px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}

.panSzjc {	
	top: 504px;
	left: 599px;
	width: 552px;
	height: 411px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}



.panSxlc {	
	top: 79px;
	/* left: 2691px; */
	right: 30px;
	width: 554px;
	height: 837px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	  
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panKygk {	
	top: 79px;
	/* left: 3260px; */
	left: 2691px;
	width: 554px;
	height: 269px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panYsll {	
	top: 363px;
	/* left: 3260px; */
	left: 2691px;
	width: 554px;
	height: 269px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	  
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panJtgjll {	
	top: 646px;
	/* left: 3260px; */
	left: 2691px;
	width: 554px;
	height: 269px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	  
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}



.nav {
    
    z-index: 100;
    
    top: 100px;
    left: 523px;
    width: 453px;
    height: 76px;

    position:absolute;
	background: url("~@/assets/images/qwys/mapmenu.png") no-repeat; 	

}

.nav ul {   
    top: 20px;
    left: 20px; 
    position:absolute;
}

.nav li {    
	float: left;
    width: 100px;
	cursor: pointer;

    text-align: center;
}

.nav li p{    
    margin: 0 10px 0 10px;
    top: 10px;
	font-size: 16px;
	line-height: 30px;	
	text-align: center;

    
    border-radius: 15px;
    background-color: rgb(255, 255, 255, 0.02);	

	color: rgb(255, 255, 255, 0.6);	
}

.nav li .currentqwys{    
    background-color: rgb(255, 255, 255, 0.2);	
	color: rgb(255, 255, 255, 0.8);	
}


</style>