<template>

	<div class="module">
		<div class="caption">
			<p>气象状态</p>
		</div>

		<div class="day1">
			<img class="tbbig" :src="require('../../assets/images/qwys/weather/'+day1_img+'.png')" alt="">			
			<br>
			<p class="day_name">今天</p>
			<p class="day_info">{{ day1_info }}</p>
		</div>

		<div class="day2">
			<img class="tbbig" :src="require('../../assets/images/qwys/weather/'+day2_img+'.png')" alt="">	
			<br>
			<p class="day_name">明天</p>
			<p class="day_info">{{ day2_info }}</p>
		</div>
		
		<div class="day3">
			<img class="tbbig" :src="require('../../assets/images/qwys/weather/'+day3_img+'.png')" alt="">	
			<br>
			<p class="day_name">后天</p>
			<p class="day_info">{{ day3_info }}</p>
		</div>


		<div class="pan_time">				
			<ul>
				<li>
					<div>
						<!-- <p class="time_name">下午3时</p> -->
						<p class="time_name">{{ time1_name }}</p>
						<!-- <img src="~@/assets/images/qwys/天气_阴_小.png" alt=""> -->
						<img class="tblittle" :src="require('../../assets/images/qwys/weather/'+time1_img+'.png')" alt="">
						<br>
						<!-- <p class="time_info">27℃</p> -->
						<p class="time_info">{{ time1_info }}</p>
					</div>
				</li>
				<li>
					<div>
						<p class="time_name">{{ time2_name }}</p>
						<img class="tblittle" :src="require('../../assets/images/qwys/weather/'+time2_img+'.png')" alt="">
						<br>
						<p class="time_info">{{ time2_info }}</p>
					</div>
				</li>
				<li>
					<div>
						<p class="time_name">{{ time3_name }}</p>
						<img class="tblittle" :src="require('../../assets/images/qwys/weather/'+time3_img+'.png')" alt="">
						<br>
						<p class="time_info">{{ time3_info }}</p>
					</div>
				</li>
				<li>
					<div>
						<p class="time_name">{{ time4_name }}</p>
						<img class="tblittle" :src="require('../../assets/images/qwys/weather/'+time4_img+'.png')" alt="">
						<br>
						<p class="time_info">{{ time4_info }}</p>
					</div>
				</li>
				<li>
					<div>
						<p class="time_name">{{ time5_name }}</p>
						<img class="tblittle" :src="require('../../assets/images/qwys/weather/'+time5_img+'.png')" alt="">
						<br>
						<p class="time_info">{{ time5_info }}</p>
					</div>
				</li>
				<li>
					<div>
						<p class="time_name">{{ time6_name }}</p>
						<img class="tblittle" :src="require('../../assets/images/qwys/weather/'+time6_img+'.png')" alt="">
						<br>
						<p class="time_info">{{ time6_info }}</p>
					</div>
				</li>
			</ul>

		</div>

		<div class="pan_line">	
			<div class="caption2">
				<p>一周温度预报</p>
			</div>
			<div class="pan_chart" ref="chart1">
			</div>
		</div>

	</div>
  
</template>


<script>
import * as Echarts from 'echarts';
import { getWeather } from '../../network/qwys';

export default {
    data() 
	{ 		
		return { 
			timer: null,		// 定时器

			chart1: null,
			option1: null,
			
			day1_img: "yun",
			day1_info: "",
			day2_img: "yun",
			day2_info: "",
			day3_img: "yun",
			day3_info: "",

			time1_name: "",
			time1_img: "yun",
			time1_info: "",
			time2_name: "",
			time2_img: "yun",
			time2_info: "",
			time3_name: "",
			time3_img: "yun",
			time3_info: "",
			time4_name: "",
			time4_img: "yun",
			time4_info: "",
			time5_name: "",
			time5_img: "yun",
			time5_info: "",
			time6_name: "",
			time6_img: "yun",
			time6_info: "",

		} 	
	}, 
    mounted() 
	{ 
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {
			//1.初始化
			this.chart1 = Echarts.init(this.$refs.chart1);
			
			
			//2.配置数据
			this.option1 = {
				grid: {
					top: "28px",
					left: "50px",
					right: "30px",
					bottom: "52px",
				},
				tooltip: {
					show: true,
					// trigger: 'axis',
					trigger: 'item',
					// formatter: function(params) {
					// 	return params[0].axisValue + "<br>" + "最高温度：" + params[0].value;
					// }
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,		
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
					},		
					data: null,
					// data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']					
				},
				yAxis: {
					type: 'value',
					scale: true,	
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value} °C'
					},
					splitLine : {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					},
				},
				series: [
					{
						name: '最高气温',
						type: 'line',	
						color: '#FF5D3B',
						data: null,
						// data: [10, 11, 13, 11, 12, 12, 9],
						// markPoint: {
						// 	data: [
						// 		{type: 'max', name: '最大值'},
						// 		{type: 'min', name: '最小值'}
						// 	]
						// },
						// markLine: {
						// 	data: [
						// 		{type: 'average', name: '平均值'}
						// 	]
						// }
					},
					{
						name: '最低气温',
						type: 'line',
						color: '#00CCF7',
						data: null,
						// data: [1, -2, 2, 5, 3, 2, 0],
						// markPoint: {
						// 	data: [
						// 		{name: '周最低', value: -2, xAxis: 1, yAxis: -1.5}
						// 	]
						// },
						// markLine: {
						// 	data: [
						// 		{type: 'average', name: '平均值'},
						// 		[{
						// 			symbol: 'none',
						// 			x: '90%',
						// 			yAxis: 'max'
						// 		}, {
						// 			symbol: 'circle',
						// 			label: {
						// 				position: 'start',
						// 				formatter: '最大值'
						// 			},
						// 			type: 'max',
						// 			name: '最高点'
						// 		}]
						// 	]
						// }
					}
				]
			};

			// 3.传入数据
			this.chart1.setOption(this.option1);
			
			this.refresh();
		},
		
		refresh() {			

			getWeather().then(res=>{
				this.option1.xAxis.data = [res.data.data[0].date.substr(5), res.data.data[1].date.substr(5), res.data.data[2].date.substr(5), res.data.data[3].date.substr(5), res.data.data[4].date.substr(5), res.data.data[5].date.substr(5), res.data.data[6].date.substr(5)];

				this.option1.series[0].data = [res.data.data[0].tem1, res.data.data[1].tem1, res.data.data[2].tem1, res.data.data[3].tem1, res.data.data[4].tem1, res.data.data[5].tem1, res.data.data[6].tem1];
				this.option1.series[1].data = [res.data.data[0].tem2, res.data.data[1].tem2, res.data.data[2].tem2, res.data.data[3].tem2, res.data.data[4].tem2, res.data.data[5].tem2, res.data.data[6].tem2];
				this.chart1.clear();
				this.chart1.setOption(this.option1);
				

				this.day1_img = res.data.data[0].wea_img
				this.day2_img = res.data.data[2].wea_img
				this.day3_img = res.data.data[3].wea_img

				this.day1_info = res.data.data[0].wea + res.data.data[0].tem1 + "-" + res.data.data[0].tem2 + "℃"
				this.day2_info = res.data.data[1].wea + res.data.data[1].tem1 + "-" + res.data.data[1].tem2 + "℃"
				this.day3_info = res.data.data[2].wea + res.data.data[2].tem1 + "-" + res.data.data[2].tem2 + "℃"


				var nhour = new Date().getHours()
				if (nhour >= 8)
				{
					nhour -= 8 
				} else {
					nhour += 16 
				}
				if (nhour<0 || nhour+6>res.data.data[0].hours.length)
				{
					nhour = 0
				}
				

				this.time1_name = res.data.data[0].hours[nhour].hours
				this.time1_img = res.data.data[0].hours[nhour].wea_img
				this.time1_info = res.data.data[0].hours[nhour].tem + "℃"

				this.time2_name = res.data.data[0].hours[nhour+1].hours
				this.time2_img = res.data.data[0].hours[nhour+1].wea_img
				this.time2_info = res.data.data[0].hours[nhour+1].tem + "℃"
				
				this.time3_name = res.data.data[0].hours[nhour+2].hours
				this.time3_img = res.data.data[0].hours[nhour+2].wea_img
				this.time3_info = res.data.data[0].hours[nhour+2].tem + "℃"
				
				this.time4_name = res.data.data[0].hours[nhour+3].hours
				this.time4_img = res.data.data[0].hours[nhour+3].wea_img
				this.time4_info = res.data.data[0].hours[nhour+3].tem + "℃"
				
				this.time5_name = res.data.data[0].hours[nhour+4].hours
				this.time5_img = res.data.data[0].hours[nhour+4].wea_img
				this.time5_info = res.data.data[0].hours[nhour+4].tem + "℃"
				
				this.time6_name = res.data.data[0].hours[nhour+5].hours
				this.time6_img = res.data.data[0].hours[nhour+5].wea_img
				this.time6_info = res.data.data[0].hours[nhour+5].tem + "℃"

			})	


		}
	}

}
</script>


<style scoped>

.tbbig {
	width: 63px;
	height: 63px;
}
.tblittle {
	width: 30px;
	height: 30px;
}

.day1{

	top: 88px;
	left: 25px;	
	width: 167px;
	height: 132px;
	position: absolute;		
}

.day_name{
	top: 70px;
	width: 160px;
	color: rgb(255, 255, 255, 0.8);
	font-size: 18px;
	line-height: 12px;
  	position:absolute;
	
}
.day_info{
	top: 34px;
	color: rgb(255, 255, 255, 0.8);
	font-size: 16px;
	line-height: 5px;
  	position:relative;	
}

.day2{
	top: 88px;
	left: 185px;	
	width: 167px;
	height: 132px;
	position: absolute;	
	font-size: 18px;
	color: rgb(255, 255, 255);
	line-height: 18px;
	
}

.day3{
	top: 88px;
	left: 345px;	
	width: 167px;
	height: 132px;
	position: absolute;	
	font-size: 18px;
	color: rgb(255, 255, 255);
	line-height: 18px;
	
}



.pan_time {	
	top: 220px;
	left: 20px;
	width: 514px;
	height: 123px;
	position: absolute;		
	background: url("~@/assets/images/qwys/pantime.png") no-repeat left; 
	  
}

.pan_time ul {
	margin: 0;
	padding: 14px;
	left: 0px;

}
.pan_time li {
	padding: 4px 0 0 0;
	float: left;
	width: 80px;
	height: 100px;
}

.time_name{
	padding: 14px 0 14px 0;
	color: rgb(255, 255, 255, 1);
	font-size: 16px;
	line-height: 0px;	
}
.time_info{
	padding: 14px 0 14px 0;
	color: rgb(255, 255, 255, 0.8);
	font-size: 16px;
	line-height: 0px;	
}


.pan_line {	
	top: 355px;
	left: 20px;
	width: 514px;
	height: 180px;
	position: absolute;		
	border-radius:16px;
	background-color: #152a3a;	  
}


.pan_chart {	
	width: 100%;
	height: 100%;
}


</style>