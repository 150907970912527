<template>
		
    <div id="cesiumContainer">        
    </div>

</template>


<script>
import { MapInit, MapViewInit, MapAddDom_Tianditu, MapAddDem, MapViewerRemoveAll, MapDebugCamera, MapPolyline, MapFlyToDuration, MapAdd3DTiles, MapAdd3DModel, MapParabola, MapAnimatedParabola, MapAddTiltPhoto } from './mapbase';

import { getMedia, getCode } from '../../network/media';
import { get3dTiles } from '../../network/gis';


export default {
    data () {
        return {
            earth: {},
            viewer: {},
        
			gisSetting: {},
			gisDemSetting: {},
            gis3dTilesData: [],

            circleprimitive: null,
        }
    },
    
    mounted (){

        XE.ready().then(this.init); 
          
    },
    
    methods: {
        
        init() {
            
            // 
            this.earth = new XE.Earth('cesiumContainer');
            this.viewer = this.earth.czm.viewer;
            window.viewer = this.viewer;
            MapViewInit(this.viewer);

            // 影像和高程
            MapAddDom_Tianditu(this.viewer);
            this.initGisDem();



            // cesium 左键点击事件
            var that = this;
            var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function (movement) {
                var pick = viewer.scene.pick(movement.position);
                MapDebugCamera(that.viewer, movement);                // 摄像机信息
                
                // if (Cesium.defined(pick) && (pick.id.id === 'id')) {
                if (Cesium.defined(pick) ) {                // 点击实体 
                    console.log(pick.id.name)
                    console.log(pick.id.desc)
                    if (pick.id.type === 'show') {
                        that.$parent.openInfo(pick.id.name, pick.id.desc);

                    }

                }

            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);


            MapFlyToDuration(this.viewer, 3, 118.29045293255408, 25.813807229789496, 974023.2008667688, 0, -90, 0);
            


            // 添加福建行政区图
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/fujian.geoJson",
                    {
                        stroke: Cesium.Color.fromCssColorString("#9bf3f1"),
                        fill: Cesium.Color.PINK.withAlpha(0.2),
                        strokeWidth: 3,
                    }
                )
            );


            // 添加兴趣点
            this.AddPin("a1", "福州市晋安湖三创园", "", 119.34476011136195, 26.09132639532283, "", 48, 85, "./images/poi3.png"); 


            
            var that = this;
                            
            var labeltabs = document.querySelectorAll(".nav ul li p")
            for (var i = 0; i<labeltabs.length; i++) {
                labeltabs[i].addEventListener('click', function() {
                    
                    for (var j = 0; j < labeltabs.length; j++) {
                        labeltabs[j].className = '';
                    }
                    this.className = 'currentqwys';

                    that.viewerremoveCircle();

                    var index = this.getAttribute('index');  
                    switch(index) {
                        case "0":
                            that.showJT();
                            break;
                        case "1":
                            that.showDL();
                            break;
                        case "2":
                            that.showHK();
                            break;
                        case "3":
                            that.showJJ();
                            break;
                        default:

                    }                        
                })
            }
        },


        // 清空圆
        viewerremoveCircle() {
            if (this.circleprimitive != null) {
                this.circleprimitive.destroy();
            }
        },


        // 交通优势
        showJT() {
            MapViewerRemoveAll(this.viewer);            
            MapFlyToDuration(this.viewer, 3, 119.1615990784875, 25.461432088988435, 20829.16202385373, 0, -90, 0);

            this.AddPin("a1", "莆田高新技术产业开发区", "", 119.139816,25.463617, "", 73, 178, "./images/poi1.png");  


            // 路网
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/data/shulines.geojson",
                    {
                        stroke: Cesium.Color.AQUA,
                        fill: Cesium.Color.PINK.withAlpha(0.3),
                        strokeWidth: 3,
                    }
                )
            );
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/data/hengLines.geojson",
                    {
                        stroke: Cesium.Color.AQUA,
                        fill: Cesium.Color.PINK.withAlpha(0.3),
                        strokeWidth: 3,
                    }
                )
            );

        },

        // 地理优势1
        showDL() {
            MapViewerRemoveAll(this.viewer);
            
            // 三维建筑模型(加载桥)
            this.init3DTiles();

            MapFlyToDuration(this.viewer, 3, 119.21615644961533, 25.417437393182244, 1729.1130402615677, 37.04613363829635, -22.77573870972719, 0.09733270837586515);

            // 添加文字
            this.viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(119.24013396666443, 25.450276208702995),
                label: {
                    text: "江涵大桥",
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    showBackground: true,
                    backgroundColor: new Cesium.Color(0.5, 0.6, 1, 0.8),
                }
            });
            this.viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(119.2235349628641, 25.45158892838348),
                label: {
                    text: "涵江",
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    showBackground: true,
                    backgroundColor: new Cesium.Color(0.5, 0.6, 1, 0.8),
                }
            });
            this.viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(119.26549976775924, 25.443338027777866),
                label: {
                    text: "福州自贸区",
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    showBackground: true,
                    backgroundColor: new Cesium.Color(0.5, 0.6, 1, 0.8),
                }
            });


            setTimeout(() => {
                this.showDL2();
            }, 6*1000);
       
        },

        // 地理优势1
        showDL2() {
            MapViewerRemoveAll(this.viewer);
            
            MapFlyToDuration(this.viewer, 3, 119.93951468807197, 25.248172879604063, 465620.8083544474, 360, -90, 0);

            this.AddPin("a1", "莆田高新技术产业开发区", "", 119.139816,25.463617, "", 73, 178, "./images/poi1.png");  
            this.AddPin("a4", "台北市", "", 121.553842,25.038429, "", 48, 85, "./images/poi2.png");    

            // 画线
            var positions = [];
            positions.push(Cesium.Cartesian3.fromDegrees(119.139816,25.463617));
            positions.push(Cesium.Cartesian3.fromDegrees(121.553842,25.038429));    // 台北

            MapPolyline(this.viewer, positions);
            // this.viewer.entities.add({
            //     polyline: {
            //         positions: positions,
            //         width: 10.0,
            //         material: new Cesium.PolylineGlowMaterialProperty({
            //             color: Cesium.Color.DEEPSKYBLUE,
            //             glowPower: 0.25,
            //         }),
            //     },
            // });


            // 添加文字
            this.viewer.entities.add({
                // id: id,
                position: Cesium.Cartesian3.fromDegrees(120.346829, 25.251023),
                label: {
                    text: "70海里",
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    showBackground: true,
                    backgroundColor: new Cesium.Color(0.5, 0.6, 1, 0.8),
                    // pixelOffset: new Cesium.Cartesian2(0, -95), //偏移量
                }
            });           
        },


        // roamPathShow() {
        //     var e = c.sysViewer;
        //     e.shouldAnimate = !0;
        //     var t = Cesium.JulianDate.fromDate(new Date(2019, 5, 27, 16, 30, 0)),
        //         r = Cesium.JulianDate.addSeconds(t, 60, new Cesium.JulianDate);

        //     e.clock.startTime = t.clone(), e.clock.stopTime = r.clone(), e.clock.currentTime = t.clone(), e.clock.clockRange = Cesium.ClockRange.CLAMPED, e.clock.multiplier = .5;
        //     var i = c.sysSceneLayers.ParkPlanning,
        //         n = c.sysViewer.scene.globe.ellipsoid,
        //         a = c.sysService + "/fjsmart3d/data/roamPath.geojson";

        //     Cesium.GeoJsonDataSource.load(a).then(function(a) {
        //         for (var o = a.entities.values[0].polyline.positions._value, A = new Cesium.SampledPositionProperty, s = 0; s < o.length; s++) {
        //             var p = o[s],
        //                 O = new Cesium.Cartesian3(p.x, p.y, p.z),
        //                 g = n.cartesianToCartographic(O),
        //                 L = Cesium.Math.toDegrees(g.latitude),
        //                 c = Cesium.Math.toDegrees(g.longitude),
        //                 l = 1 * s,
        //                 E = Cesium.JulianDate.addSeconds(t, l, new Cesium.JulianDate),
        //                 D = Cesium.Cartesian3.fromDegrees(c, L, 15);
        //             A.addSample(E, D)
        //         }
        //         var d = i.entities.add({
        //             availability: new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({
        //                 start: t,
        //                 stop: r
        //             })]),
        //             position: A,
        //             orientation: new Cesium.VelocityOrientationProperty(A),
        //             model: {
        //                 uri: "static/data/models/Cesium_Air.gltf",
        //                 minimumPixelSize: 68,
        //                 color: Cesium.Color.WHITE
        //             },
        //             path: {
        //                 resolution: 1,
        //                 material: new Cesium.PolylineGlowMaterialProperty({
        //                     glowPower: .1,
        //                     color: new Cesium.Color(21 / 255, 1, 1, 1)
        //                 }),
        //                 width: 10
        //             }
        //         });
        //         e.trackedEntity = d
        //     }).otherwise(function(e) {})
        // },


        // showFlowines: function(e) {
        //     var t = c.sysSceneLayers.digitalSceneData,
        //         i = {
        //             flowImage: r("Tk2w"),
        //             options: {
        //                 name: "yscNoNeedEntity",
        //                 polyline: {
        //                     width: 4,
        //                     material: [Cesium.Color.CORAL, 2e3]
        //                 }
        //             }
        //         };
        //     d.initPolylineTrailLinkMaterialProperty(i);

        //     var n = i.options.polyline.material[0],
        //         a = i.options.polyline.material[1];
        //     i.options.polyline.material = new Cesium.PolylineTrailLinkMaterialProperty(n, a);
        //     for (var o = 0; o < e.length; o++) {
        //         var A = e[o];
        //         A.polyline.width = 4, i.options.polyline.positions = A.polyline.positions, t.entities.add(i.options)
        //     }
        // },
        
        
        // 航空优势
        showHK() {
            MapViewerRemoveAll(this.viewer);
            MapFlyToDuration(this.viewer, 2, 119.43655982072993, 23.308428953606924, 222642.81803446854, 345.6838932713683, -42.5313781985169, 0.0032177547250900602);

            // 添加兴趣点
            this.AddPin("a1", "莆田高新技术产业开发区", "", 119.139816,25.463617, "", 73, 178, "./images/poi1.png");       
            this.AddPin("a2", "福州长乐国际机场", "show", 119.676404,25.934598, "96", 48, 85, "./images/poi2.png");   
            this.AddPin("a3", "厦门高崎国际机场", "show", 118.143639,24.545038, "174", 48, 85, "./images/poi2.png");   
            this.AddPin("a4", "泉州晋江国际机场", "show", 118.599816,24.800826, "104", 48, 85, "./images/poi2.png");    

            // 抛物线
            var twoPoints1 = [119.139816,25.463617, 119.676404,25.934598];
            var twoPoints2 = [119.139816,25.463617, 118.143639,24.545038];
            var twoPoints3 = [119.139816,25.463617, 118.599816,24.800826];

            // MapAnimatedParabola(this.viewer, twoPoints1);
            // MapAnimatedParabola(this.viewer, twoPoints2);
            // MapAnimatedParabola(this.viewer, twoPoints3);
            MapParabola(this.viewer, twoPoints1);
            MapParabola(this.viewer, twoPoints2);
            MapParabola(this.viewer, twoPoints3);


            // MapAdd3DModel(this.viewer, 'model/Cesium_Air.gltf', 119.139816, 25.463617, 100.0);
            // MapAdd3DModel(this.viewer, 'model/Cesium_Air.gltf', 119.676404, 25.934598, 100.0);
            // MapAdd3DModel(this.viewer, 'model/Cesium_Air.gltf', 118.143639, 24.545038, 100.0);
            // MapAdd3DModel(this.viewer, 'model/Cesium_Air.gltf', 118.599816, 24.800826, 100.0);
            
        },
        
        // 经济优势
        showJJ() {
            MapViewerRemoveAll(this.viewer);
            MapFlyToDuration(this.viewer, 2, 115.59332585484336, 32.205532377888936, 4067668.8071600073, 0, -90, 0);
            

            // this.AddPin("a1", "莆田高新技术产业开发区", "", 119.139816,25.463617, "", 73, 178, "./images/poi1.png");  

            
            // 画雷达扫描
            // var CartographicCenter = new Cesium.Cartographic(Cesium.Math.toRadians(119.151171), Cesium.Math.toRadians(25.480318), 0);
            // var scanColor = new Cesium.Color(1.0, 1.0, 1.0, 1);
            // this.AddRadarScanPostStage(this.viewer, CartographicCenter, 1000000, scanColor, 4000);
                        

            // // 画圆扩散1
            // var lng = 119.151171
            // var lat = 25.480318
            // var cartographicCenter = new Cesium.Cartographic(Cesium.Math.toRadians(lng), Cesium.Math.toRadians(lat), 0)
            // var scanColor = new Cesium.Color(1.0, 1.0, 1.0, 1);
            // // 创建自定义的 PostProcessStage
            // var dynamicCircle = this.createDynamicCircleStage(viewer, Cesium, cartographicCenter, 1000000, scanColor, 4000)
            // // 添加进场景
            // this.viewer.scene.postProcessStages.add(dynamicCircle)


            // 贴地图片            
            this.viewer.entities.add({
                rectangle: {
                    coordinates: Cesium.Rectangle.fromDegrees(119.139816-17, 25.463617-15, 119.139816+17, 25.463617+15),
                    material: "./images/radarbg.png",
                },
            });


            // 画圆
            viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(116.43014,39.913828),
                ellipse: {
                    semiMinorAxis: 200000.0,
                    semiMajorAxis: 200000.0,
                    height: 0.0,
                    material: Cesium.Color.fromCssColorString("rgba(53, 126, 148, 0.3)"),
                    outline: true,
                    outlineColor: Cesium.Color.fromCssColorString("rgba(43, 246, 249, 1)"),
                }
            });            
            // 添加文字
            this.viewer.entities.add({
                // id: id,
                position: Cesium.Cartesian3.fromDegrees(116.43014,39.913828),
                label: {
                    text: "京津冀经济区",
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    // showBackground: true,
                    // backgroundColor: new Cesium.Color(0.5, 0.6, 1, 0.8),
                    // pixelOffset: new Cesium.Cartesian2(0, -95), //偏移量
                }
            });

            // 画圆
            viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(121.470999, 31.259025),
                ellipse: {
                    semiMinorAxis: 200000.0,
                    semiMajorAxis: 200000.0,
                    height: 0.0,
                    material: Cesium.Color.fromCssColorString("rgba(53, 126, 148, 0.3)"),
                    outline: true,
                    outlineColor: Cesium.Color.fromCssColorString("rgba(43, 246, 249, 1)"),
                }
            });            
            // 添加文字
            this.viewer.entities.add({
                // id: id,
                position: Cesium.Cartesian3.fromDegrees(121.470999, 31.259025),
                label: {
                    text: "长三角经济区",
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    // showBackground: true,
                    // backgroundColor: new Cesium.Color(0.5, 0.6, 1, 0.8),
                    // pixelOffset: new Cesium.Cartesian2(0, -95), //偏移量
                }
            });
            
            // 画圆
            viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(113.661347,22.819085),
                ellipse: {
                    semiMinorAxis: 200000.0,
                    semiMajorAxis: 200000.0,
                    height: 0.0,
                    material: Cesium.Color.fromCssColorString("rgba(53, 126, 148, 0.3)"),
                    outline: true,
                    outlineColor: Cesium.Color.fromCssColorString("rgba(43, 246, 249, 1)"),
                }
            });            
            // 添加文字
            this.viewer.entities.add({
                // id: id,
                position: Cesium.Cartesian3.fromDegrees(113.661347,22.819085),
                label: {
                    text: "粤港澳经济区",
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    // showBackground: true,
                    // backgroundColor: new Cesium.Color(0.5, 0.6, 1, 0.8),
                    // pixelOffset: new Cesium.Cartesian2(0, -95), //偏移量
                }
            });


            // 画圆扩散2
            const circleSource = `
            czm_material czm_getMaterial(czm_materialInput materialInput)
            {
                czm_material material = czm_getDefaultMaterial(materialInput);
                vec2 st = materialInput.st;
                vec3 diffuse = color.rgb;
                float alpha = 0.0;
                float u_time = (czm_frameNumber / 90.0);
                float dis = distance(st, vec2(0.5));
                        
                alpha = 0.0;
            
                if(dis <  fract(u_time * 0.5) / 2.0 ) {
                    diffuse = color.rgb;
                }
            
                if(dis <  fract(u_time * 0.5 - 0.5) / 2.0 ) {
                    diffuse = color.rgb;
                    alpha = dis / fract(u_time * 0.5 - 0.5);
                }
                        
                material.diffuse = diffuse;
                material.alpha = alpha;
                return material;
            }
            `    

            var primitive = new Cesium.Primitive({
                geometryInstances: new Cesium.GeometryInstance({
                    geometry: new Cesium.EllipseGeometry({
                        center: Cesium.Cartesian3.fromDegrees(119.151171, 25.480318),
                        semiMajorAxis: 1680000.0,
                        semiMinorAxis: 1680000.0,
                    }),
                }),
                appearance: new Cesium.EllipsoidSurfaceAppearance({
                    material: new Cesium.Material({
                        fabric: {
                            type: 'mym',
                            uniforms: {
                                color: new Cesium.Color(1.0, 1.0, 1.0, 1), // 颜色
                            },
                            source: circleSource,
                        },
                    }),
                }),
            });
            this.circleprimitive = this.viewer.scene.primitives.add(primitive);

        },
        
        

        // 添加三维建筑模型
        init3DTiles() {

			// Gis配置（15）
			getCode("gis_set", "15").then(res=>{
				this.gisSetting = res.data[0];					

                get3dTiles().then(res=>{
                    this.gis3dTilesData = res.data;	

                    // for (var i=0; i<this.gis3dTilesData.length; i++) {
                    for (var i=27; i<=27; i++) {                    // 28=江涵大桥
                        if (this.gis3dTilesData[i].isshow == 0) {
                            continue;
                        }

                        var url = this.gisSetting.value1 + '3dtiles/' + this.gis3dTilesData[i].name + '/tileset.json';

                        var strarr_material = this.gis3dTilesData[i].arr_material.split(",");
                        var arr_material = strarr_material.map((value)=>{
                            return  parseFloat(value);
                        })


                        var strarr_transform = this.gis3dTilesData[i].arr_transform.split(",");
                        var arr_transform = strarr_transform.map((value)=>{
                            return  parseFloat(value);
                        })

                        MapAdd3DTiles(this.viewer, url, arr_transform, arr_material);
                    }
                                    
                })

			})
            
        },


        initGisDem() {
            
			// Gis配置（16）
			getCode("gis_set", "16").then(res=>{
				this.gisDemSetting = res.data[0];					
                
                console.log(this.gisDemSetting.value1);
                // MapAddDem(this.viewer, "http://39.107.47.88:8015/fjsmart3d/3dtiles/dem/fujian/quansheng");
                MapAddDem(this.viewer, this.gisDemSetting.value1);
			})

        },
        
        // 添加兴趣点
        AddPin(id, name, type, lat, lng, desc, width, height, img){
                
            this.viewer.entities.add({
                id: id,
                name: name,
                type: type,
                desc: desc,
                position: Cesium.Cartesian3.fromDegrees(lat, lng),
                label: {
                    text: name,
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    pixelOffset: new Cesium.Cartesian2(0, -95), //偏移量
                },
                billboard: {                    
                    image: img,
                    width: width,
                    height: height,

                    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,//垂直位置
                    horizontalOrigin :Cesium.HorizontalOrigin.CENTER,//水平位置
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                }
            });

        },

    
        /*
        添加雷达扫描线 地形遮挡开启   lon:-74.01296152309055 lat:40.70524201566827 height:129.14366696393927
        viewer
        cartographicCenter 扫描中心
        radius  半径 米
        scanColor 扫描颜色
        duration 持续时间 毫秒
        */
        AddRadarScanPostStage(viewer, cartographicCenter, radius, scanColor, duration) {
            
            var ScanSegmentShader =
                "uniform sampler2D colorTexture;\n" +
                "uniform sampler2D depthTexture;\n" +
                "varying vec2 v_textureCoordinates;\n" +
                "uniform vec4 u_scanCenterEC;\n" +
                "uniform vec3 u_scanPlaneNormalEC;\n" +
                "uniform vec3 u_scanLineNormalEC;\n" +
                "uniform float u_radius;\n" +
                "uniform vec4 u_scanColor;\n" +

                "vec4 toEye(in vec2 uv, in float depth)\n" +
                " {\n" +
                " vec2 xy = vec2((uv.x * 2.0 - 1.0),(uv.y * 2.0 - 1.0));\n" +
                " vec4 posInCamera =czm_inverseProjection * vec4(xy, depth, 1.0);\n" +
                " posInCamera =posInCamera / posInCamera.w;\n" +
                " return posInCamera;\n" +
                " }\n" +

                "bool isPointOnLineRight(in vec3 ptOnLine, in vec3 lineNormal, in vec3 testPt)\n" +
                "{\n" +
                    "vec3 v01 = testPt - ptOnLine;\n" +
                    "normalize(v01);\n" +
                    "vec3 temp = cross(v01, lineNormal);\n" +
                    "float d = dot(temp, u_scanPlaneNormalEC);\n" +
                    "return d > 0.5;\n" +
                "}\n" +

                "vec3 pointProjectOnPlane(in vec3 planeNormal, in vec3 planeOrigin, in vec3 point)\n" +
                "{\n" +
                    "vec3 v01 = point -planeOrigin;\n" +
                    "float d = dot(planeNormal, v01) ;\n" +
                    "return (point - planeNormal * d);\n" +
                "}\n" +

                "float distancePointToLine(in vec3 ptOnLine, in vec3 lineNormal, in vec3 testPt)\n" +
                "{\n" +
                    "vec3 tempPt = pointProjectOnPlane(lineNormal, ptOnLine, testPt);\n" +
                    "return length(tempPt - ptOnLine);\n" +
                "}\n" +

                "float getDepth(in vec4 depth)\n" +
                "{\n" +
                    "float z_window = czm_unpackDepth(depth);\n" +
                    "z_window = czm_reverseLogDepth(z_window);\n" +
                    "float n_range = czm_depthRange.near;\n" +
                    "float f_range = czm_depthRange.far;\n" +
                    "return (2.0 * z_window - n_range - f_range) / (f_range - n_range);\n" +
                "}\n" +

                "void main()\n" +
                "{\n" +
                    "gl_FragColor = texture2D(colorTexture, v_textureCoordinates);\n" +
                    "float depth = getDepth( texture2D(depthTexture, v_textureCoordinates));\n" +
                    "vec4 viewPos = toEye(v_textureCoordinates, depth);\n" +
                    "vec3 prjOnPlane = pointProjectOnPlane(u_scanPlaneNormalEC.xyz, u_scanCenterEC.xyz, viewPos.xyz);\n" +
                    "float dis = length(prjOnPlane.xyz - u_scanCenterEC.xyz);\n" +
                    "float twou_radius = u_radius * 2.0;\n" +
                    "if(dis < u_radius)\n" +
                    "{\n" +
                        "float f0 = 1.0 -abs(u_radius - dis) / u_radius;\n" +
                        "f0 = pow(f0, 64.0);\n" +
                        "vec3 lineEndPt = vec3(u_scanCenterEC.xyz) + u_scanLineNormalEC * u_radius;\n" +
                        "float f = 0.0;\n" +
                        "if(isPointOnLineRight(u_scanCenterEC.xyz, u_scanLineNormalEC.xyz, prjOnPlane.xyz))\n" +
                        "{\n" +
                            "float dis1= length(prjOnPlane.xyz - lineEndPt);\n" +
                            "f = abs(twou_radius -dis1) / twou_radius;\n" +
                            "f = pow(f, 3.0);\n" +
                        "}\n" +
                        "gl_FragColor = mix(gl_FragColor, u_scanColor, f + f0);\n" +
                    "}\n" +
                "}\n";

            var _Cartesian3Center = Cesium.Cartographic.toCartesian(cartographicCenter);
            var _Cartesian4Center = new Cesium.Cartesian4(_Cartesian3Center.x, _Cartesian3Center.y, _Cartesian3Center.z, 1);

            var _CartographicCenter1 = new Cesium.Cartographic(cartographicCenter.longitude, cartographicCenter.latitude, cartographicCenter.height + 500);
            var _Cartesian3Center1 = Cesium.Cartographic.toCartesian(_CartographicCenter1);
            var _Cartesian4Center1 = new Cesium.Cartesian4(_Cartesian3Center1.x, _Cartesian3Center1.y, _Cartesian3Center1.z, 1);

            var _CartographicCenter2 = new Cesium.Cartographic(cartographicCenter.longitude + Cesium.Math.toRadians(0.001), cartographicCenter.latitude, cartographicCenter.height);
            var _Cartesian3Center2 = Cesium.Cartographic.toCartesian(_CartographicCenter2);
            var _Cartesian4Center2 = new Cesium.Cartesian4(_Cartesian3Center2.x, _Cartesian3Center2.y, _Cartesian3Center2.z, 1);
            var _RotateQ = new Cesium.Quaternion();
            var _RotateM = new Cesium.Matrix3();

            var _time = (new Date()).getTime();

            var _scratchCartesian4Center = new Cesium.Cartesian4();
            var _scratchCartesian4Center1 = new Cesium.Cartesian4();
            var _scratchCartesian4Center2 = new Cesium.Cartesian4();
            var _scratchCartesian3Normal = new Cesium.Cartesian3();
            var _scratchCartesian3Normal1 = new Cesium.Cartesian3();

            var ScanPostStage = new Cesium.PostProcessStage({
                fragmentShader: ScanSegmentShader,
                uniforms: {
                    u_scanCenterEC: function () {
                        return Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center, _scratchCartesian4Center);
                    },
                    u_scanPlaneNormalEC: function () {
                        var temp = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center, _scratchCartesian4Center);
                        var temp1 = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center1, _scratchCartesian4Center1);
                        _scratchCartesian3Normal.x = temp1.x - temp.x;
                        _scratchCartesian3Normal.y = temp1.y - temp.y;
                        _scratchCartesian3Normal.z = temp1.z - temp.z;

                        Cesium.Cartesian3.normalize(_scratchCartesian3Normal, _scratchCartesian3Normal);
                        return _scratchCartesian3Normal;
                    },
                    u_radius: radius,
                    u_scanLineNormalEC: function () {
                        var temp = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center, _scratchCartesian4Center);
                        var temp1 = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center1, _scratchCartesian4Center1);
                        var temp2 = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center2, _scratchCartesian4Center2);

                        _scratchCartesian3Normal.x = temp1.x - temp.x;
                        _scratchCartesian3Normal.y = temp1.y - temp.y;
                        _scratchCartesian3Normal.z = temp1.z - temp.z;

                        Cesium.Cartesian3.normalize(_scratchCartesian3Normal, _scratchCartesian3Normal);

                        _scratchCartesian3Normal1.x = temp2.x - temp.x;
                        _scratchCartesian3Normal1.y = temp2.y - temp.y;
                        _scratchCartesian3Normal1.z = temp2.z - temp.z;

                        var tempTime = (((new Date()).getTime() - _time) % duration) / duration;
                        Cesium.Quaternion.fromAxisAngle(_scratchCartesian3Normal, tempTime * Cesium.Math.PI * 2, _RotateQ);
                        Cesium.Matrix3.fromQuaternion(_RotateQ, _RotateM);
                        Cesium.Matrix3.multiplyByVector(_RotateM, _scratchCartesian3Normal1, _scratchCartesian3Normal1);
                        Cesium.Cartesian3.normalize(_scratchCartesian3Normal1, _scratchCartesian3Normal1);
                        return _scratchCartesian3Normal1;
                    },
                    u_scanColor: scanColor
                }
            });

            viewer.scene.postProcessStages.add(ScanPostStage);

        },



        // 通过Shader添加圆形扩散效果
        createDynamicCircleStage(viewer, Cesium, cartographicCenter, maxRadius, scanColor, duration) {
                
            const DynamicCircle = `
                uniform sampler2D colorTexture;    //颜色纹理
                uniform sampler2D depthTexture;    //深度纹理
                varying vec2 v_textureCoordinates; //纹理坐标
                uniform vec4 u_scanCenterEC;       //扫描中心
                uniform vec3 u_scanPlaneNormalEC;  //扫描平面法向量
                uniform float u_radius;            //扫描半径
                uniform vec4 u_scanColor;          //扫描颜色

                // 根据二维向量和深度值 计算距离camera的向量
                vec4 toEye(in vec2 uv, in float depth) {
                    vec2 xy = vec2((uv.x * 2.0 - 1.0), (uv.y * 2.0 - 1.0));
                    // 看看源码中关于此函数的解释是，cesium系统自动生成的4*4的反投影变换矩阵
                    // 从clip坐标转为眼睛坐标，clip坐标是指顶点着色器的坐标系统gl_position输出的
                    vec4 posInCamera = czm_inverseProjection * vec4(xy, depth, 1.0);
                    posInCamera = posInCamera / posInCamera.w; //将视角坐标除深度分量
                    return posInCamera;
                }

                // 点在平面上的投影，输入参数为 平面法向量，平面起始点，测试点
                vec3 pointProjectOnPlane(in vec3 planeNormal, in vec3 planeOrigin, in vec3 point) {
                    // 计算测试点与平面起始点的向量
                    vec3 v01 = point - planeOrigin;
                    // 平面法向量与 测试点与平面上的点 点积  点积的几何意义，b在a上的投影长度，
                    // 即v01在平面法向量上的长度
                    float d = dot(planeNormal, v01);
                    // planeNormal * d 即为v01在平面法向量上的投影向量
                    // 根据三角形向量相加为0的原则 即可得点在平面上的投影
                    return (point - planeNormal * d);
                }

                // 获取深度值，根据纹理坐标获取深度值
                float getDepth(in vec4 depth) {
                    float z_window = czm_unpackDepth(depth);  //源码解释将一个vec4向量还原到0，1内的一个数
                    z_window = czm_reverseLogDepth(z_window); // czm_reverseLogDepth解开深度
                    float n_range = czm_depthRange.near;      //
                    float f_range = czm_depthRange.far;
                    return (2.0 * z_window - n_range - f_range) / (f_range - n_range);
                }

                void main() {
                    gl_FragColor = texture2D(colorTexture, v_textureCoordinates);          //片元颜色
                    float depth = getDepth(texture2D(depthTexture, v_textureCoordinates)); //根据纹理获取深度值
                    vec4 viewPos = toEye(v_textureCoordinates, depth);                     //根据纹理坐标和深度值获取视点坐标
                    // 点在平面上的投影，平面法向量，平面中心，视点坐标
                    vec3 prjOnPlane = pointProjectOnPlane(u_scanPlaneNormalEC.xyz, u_scanCenterEC.xyz, viewPos.xyz);
                    // 计算投影坐标到视点中心的距离
                    float dis = length(prjOnPlane.xyz - u_scanCenterEC.xyz);
                    // 如果在扫描半径内，则重新赋值片元颜色
                    if (dis < u_radius) {
                        // 计算与扫描中心的距离并归一化
                        float f = dis / u_radius;
                        // 原博客如下，实际上可简化为上式子
                        // float f = 1.0 -abs(u_radius - dis) / u_radius;
                        // 四次方
                        f = pow(f, 2.0);
                        // mix(x, y, a): x, y的线性混叠， x(1-a)  y*a;,
                        // 效果解释：在越接近扫描中心时，f越小，则片元的颜色越接近原来的，相反则越红
                        gl_FragColor = mix(gl_FragColor, u_scanColor, f);
                    }
                }
                `


            // 中心点
            var _Cartesian3Center = Cesium.Cartographic.toCartesian(cartographicCenter);
            var _Cartesian4Center = new Cesium.Cartesian4(_Cartesian3Center.x, _Cartesian3Center.y, _Cartesian3Center.z, 1);

            // 中心点垂直高度上升500m的坐标点，目的是为了计算平面的法向量
            var _CartographicCenter1 = new Cesium.Cartographic(cartographicCenter.longitude, cartographicCenter.latitude, cartographicCenter.height + 500);
            var _Cartesian3Center1 = Cesium.Cartographic.toCartesian(_CartographicCenter1);
            var _Cartesian4Center1 = new Cesium.Cartesian4(_Cartesian3Center1.x, _Cartesian3Center1.y, _Cartesian3Center1.z, 1);

            // 当前时间
            var _time = (new Date()).getTime();

            // 转换成相机参考系后的中心点，上升高度后的中心点以及平面法向量
            var _scratchCartesian4Center = new Cesium.Cartesian4();
            var _scratchCartesian4Center1 = new Cesium.Cartesian4();
            var _scratchCartesian3Normal = new Cesium.Cartesian3();

            // 自定义PostProcessStage
            var dynamicCircle = new Cesium.PostProcessStage({
                fragmentShader: DynamicCircle,
                uniforms: {
                    // 将中心点坐标转化到相机参考系
                    u_scanCenterEC: function () {
                        return Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center, _scratchCartesian4Center);
                    },
                    // 计算相机参考系下的平面法向量
                    u_scanPlaneNormalEC: function () {
                        var temp = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center, _scratchCartesian4Center);
                        var temp1 = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center1, _scratchCartesian4Center1);
                        _scratchCartesian3Normal.x = temp1.x - temp.x;
                        _scratchCartesian3Normal.y = temp1.y - temp.y;
                        _scratchCartesian3Normal.z = temp1.z - temp.z;

                        Cesium.Cartesian3.normalize(_scratchCartesian3Normal, _scratchCartesian3Normal);
                        return _scratchCartesian3Normal;
                    },
                    // 动态半径
                    u_radius: function () {
                        return maxRadius * (((new Date()).getTime() - _time) % duration) / duration;
                    },
                    u_scanColor: scanColor
                }
            });
            return dynamicCircle;
        },


    }               

}


</script>


<style scoped>


</style>