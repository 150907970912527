<template>

	<div class="main">
		<div class="caption">
			<p>运输流量</p>
		</div>

		<div class="pan_line1">	
			<div class="pan">				
				<ul>
					<li>			
						<div class="pan_chart" ref="chart1">
						</div>
					</li>
					<li>					
						<div class="pan_chart" ref="chart2">
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
  
</template>


<script>
import * as Echarts from 'echarts';
import { getYsllzt, getYslllx } from '../../network/qwys';

export default {
    data() 
	{ 
		return { 
			timer: null,		// 定时器

			chart1: null,
			option1: null,

			chart2: null,
			option2: null
		} 	
	}, 
    mounted() 
	{ 
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {

		init() {
			//1.初始化
			this.chart1 = Echarts.init(this.$refs.chart1);
			this.chart2 = Echarts.init(this.$refs.chart2);	
			
			//2.配置数据
			this.option1 = {			
				tooltip: {
					// trigger: "axis",
					trigger: "item",
					axisPointer: {
						type: "shadow",
						textStyle: {
							color: "#fff"
						}
					},
					confine: !0,
					textStyle: {
						fontSize: 12
					},
					extraCssText: "width:120px;height:120px"
				},
				title: {
					show: !0,
					text: "",
					x: "center",
					y: "5%",
					textAlign: null,
					textStyle: {
						fontSize: 18,
						color: "#ffffff"
					}
				},
				legend: {
					type: "scroll",
					right: "5%",
					top: "0%",
					textStyle: {
						color: 'rgba(255, 255, 255, 0.8)',	
						fontSize: 12
					}
				},
				grid: [{
					left: "2%",
					bottom: "0%",
					top: "18%",
					right: "3%",
					containLabel: !0
				}],
				xAxis: {
					type: "category",
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
					axisLabel:{
						formatter:function(value){
							return value.split("").join("\n");
						}
					},
					data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
				},
				yAxis: [{
					type: "value",
					name: "",
					nameTextStyle: {
						color: "#ffffff"
					},
					position: "left",					
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					}
				}],
				series: [{
					name: "拥堵",
					type: "bar",
					stack: "总量",
					barMaxWidth: 11,
					barGap: "10%",
					itemStyle: {
						normal: {
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [{
									offset: 0,
									color: "#EB9200"
								},
								{
									offset: 1,
									color: "#EB9200"
								}],
								global: !1
							}
						}
					},
					data: null
					// data: [100, 120, 90, 110, 240, 230, 240]
				},
				{
					name: "畅通",
					type: "bar",
					stack: "总量",
					itemStyle: {
						normal: {
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [{
									offset: 0,
									color: "#08CFF6"
								},
								{
									offset: 1,
									color: "#08CFF6"
								}],
								global: !1
							},
							barBorderRadius: 0
						}
					},
					data: null
					// data: [300, 310, 320, 330, 280, 550, 400]
				},
				{
					name: "乘客量",
					type: "line",
					symbolSize: 5,
					symbol: "circle",
					itemStyle: {
						normal: {
							color: "#AEFFE7",
							barBorderRadius: 0
						}
					},
					lineStyle: {
						normal: {
							width: 2,
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [{
									offset: 0,
									color: "#AEFFE7"
								},
								{
									offset: 1,
									color: "#AEFFE7"
								}],
								global: !1
							}
						}
					},
					data: null
					// data: [480, 420, 150, 200, 400, 330, 300]
				}]
			};
			
			this.option2 = {
				title: {
					left: "center",
					top: 2,
					textStyle: {
						fontSize: 20
					}
				},
				color: ["#08CFF6", "#DDFFF8"],
				tooltip: {
					trigger: "item",
					axisPointer: {
						type: "shadow"
					}
				},
				grid: [{
					left: "4%",
					bottom: "0%",
					top: "18%",
					right: "0%",
					containLabel: !0
				}],
				legend: {					
					right: "5%",
					top: "0%",					
					data: ["货运", "客运"],
					textStyle: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					}
				},
				xAxis: [{
					type: "category",
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
					axisLabel:{
						formatter:function(value){
							return value.split(" ").join("\n");
						}
					},
					data: ["1 月", "2 月", "3 月", "4 月", "5 月", "6 月", "7 月", "8 月", "9 月", "10 月", "11 月", "12 月"],
					axisTick: {
						alignWithLabel: !0
					}
				}],
				yAxis: [{
					type: "value",
					name: "",
					nameTextStyle: {
						color: "#ffffff"
					},				
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					}
				}],
				series: [{					
					name: "货运",
					type: "bar",
					barGap: "10%",
					data: null
					// data: [60, 52, 56, 55, 62, 65, 64, 52, 56, 55, 62, 65]
				},
				{
					name: "客运",
					type: "bar",
					barGap: "10%",
					data: null
					// data: [56, 52, 56, 55, 62, 65, 64, 52, 55, 62, 65, 57]
				}]
			};

			// 3.传入数据
			this.chart1.setOption(this.option1);
			this.chart2.setOption(this.option2);

			this.refresh();
		},

		refresh() {	
			
			getYsllzt().then(res=>{
				this.option1.series[0].data = [res.data[0].yd, res.data[1].yd, res.data[2].yd, res.data[3].yd, res.data[4].yd, res.data[5].yd, res.data[6].yd];
				this.option1.series[1].data = [res.data[0].ct, res.data[1].ct, res.data[2].ct, res.data[3].ct, res.data[4].ct, res.data[5].ct, res.data[6].ct];
				this.option1.series[2].data = [res.data[0].ckl, res.data[1].ckl, res.data[2].ckl, res.data[3].ckl, res.data[4].ckl, res.data[5].ckl, res.data[6].ckl];

				this.chart1.clear();
				this.chart1.setOption(this.option1);
			})	
			
			getYslllx().then(res=>{
				this.option2.series[0].data = [res.data[0].hy, res.data[1].hy, res.data[2].hy, res.data[3].hy, res.data[4].hy, res.data[5].hy, 
											   res.data[6].hy, res.data[7].hy, res.data[8].hy, res.data[9].hy, res.data[10].hy, res.data[11].hy];
				this.option2.series[1].data = [res.data[0].ky, res.data[1].ky, res.data[2].ky, res.data[3].ky, res.data[4].ky, res.data[5].ky, 
											   res.data[6].ky, res.data[7].ky, res.data[8].ky, res.data[9].ky, res.data[10].ky, res.data[11].ky];

				this.chart2.clear();
				this.chart2.setOption(this.option2);
			})		
		}
	}
}
</script>


<style scoped>

.main {	
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	position: absolute;			
	border-radius:16px;
	background: #0f2539;
}

.caption {	
	top: 13px;
	left: 10px;
	width: 100px;
	height: 34px;
	text-align: center;
  	position:relative;	
	background: url("~@/assets/images/main/imgcaption.png") no-repeat left; 
}

.caption p {	
	left: 32px;
	color: #F1F1F1;
  	font-size: 18px;
  	line-height: 34px;
  	text-align: left;
  	position:relative;	
}

.pan_line1 {	
	top: 50px;
	left: 20px;
	width: 514px;
	height: 205px;
	position: absolute;		
	border-radius:16px;
	background-color: #152a3a;	  
}

.pan {	
	top: -10px;
	left: -10px;
	width: 534px;
	height: 184px;
  	position:relative;
}

.pan ul {
	margin: 0;
	padding: 14px;
	left: 0px;

}
.pan li {
	float: left;
	width: 253px;
	height: 195px;
}

.pan_chart {	
	width: 100%;
	height: 100%;

}


</style>