<template>

	<div class="main">
		<div class="caption">
			<p>水质监测</p>
		</div>
		
		<div class="pan_line">	
			<div class="pan_chart" ref="chart">
			</div>
		</div>
	</div>
  
</template>


<script>
import * as Echarts from 'echarts';
import { getSzjc } from '../../network/qwys';

export default {
    data() 
	{ 
		return { 
			timer: null,		// 定时器
			chart: null,
			option: null
		} 	
	}, 

    mounted() 
	{ 
		this.init() 
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {
			// 1.初始化
			this.chart = Echarts.init(this.$refs.chart);			
			
			// 2.配置数据
			this.option = {
				grid: {
					left: "2%",
					right: "2%",
					top: "12%",
					bottom: "5%",
					containLabel: !0
				},
				tooltip: {
					show: true,
					trigger: 'item',
				},
				xAxis: {
					data: ["溶解氧", "氨-氮", "铁", "锰", "铜", "锌", "铝", "铅", "镉", "氯化物", "氰化物", "氟化物", "硫酸盐"],
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
					axisLabel:{
						formatter:function(value){
							return value.split("").join("\n");
						}
					}
				},
				yAxis: {
					name: "单位（mg/L）",
					nameTextStyle: {
						padding: [0, 0, 0, 40],
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 15
					},			
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine : {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					},
					// interval: 5
				},
				series: [{
					type: "bar",
					barWidth: 10,
					barGap: 60,
					itemStyle: {
						normal: {
							barBorderRadius:[3, 3, 0, 0],
							color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: "#00CCF7"
							},
							{
								offset: 1,
								color: "#93FFE5"
							}], !1)
						}
					},
					label: {
						normal: {
							show: !0,
							fontSize: 12,
							color: "#ffffff",
							position: "top"
						}
					},
					data: null
					// data: [10.76, 0.02, 0.05, 0.05, 0.02, 0.05, 0.008, 0.0025, 0.0005, 14, 0.0025, 0.1, 4]
				}]
			};

			// 3.传入数据
			this.chart.setOption(this.option);
			
			this.refresh();
		},
		
		refresh() {	

			getSzjc().then(res=>{
				this.option.series[0].data = [res.data.rjy, res.data.ad, res.data.tie, res.data.meng, res.data.tong, 
							res.data.xin, res.data.lv, res.data.qian, res.data.ge, res.data.lhw, 
							res.data.qhw, res.data.fhw, res.data.lsy];

				this.chart.clear();
				this.chart.setOption(this.option);

			})
		}
	}

}
</script>


<style scoped>

.main {	
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	position: absolute;		
	border-radius:16px;
	background: #0f2539;
}

.caption {	
	top: 13px;
	left: 10px;
	width: 100px;
	height: 34px;
	text-align: center;
  	position:relative;	
	background: url("~@/assets/images/main/imgcaption.png") no-repeat left; 
}

.caption p {	
	left: 32px;
	color: #F1F1F1;
  	font-size: 18px;
  	line-height: 34px;
  	text-align: left;
  	position:relative;	
}

.pan_line {	
	top: 50px;
	left: 20px;
	width: 514px;
	height: 345px;
	position: absolute;		
	border-radius:16px;
	background-color: #152a3a;	  
}

.pan_chart {	
	width: 100%;
	height: 100%;
}


</style>