<template>

	<el-dialog title="" v-model="dialogVisible" top="23vh" width="0%" @close='closeDialog'>
		<div class="dialogA">     
			<div class="dialogB">   
				<p class="c1">莆田高新技术产业开发区</p>  
				<p class="c2">{{ target }}</p>  
				<br>
				<p class="c3">距离：{{ distance }}公里</p>  

			</div>
		</div>
	</el-dialog>

</template>


<script>

export default {

    data() { 
		return { 
			dialogVisible: false,

			target: "",
			distance: "",
		} 	
	}, 
    
	methods: {
		open(target, distance) {
			this.target = target;
			this.distance = distance;
			this.dialogVisible = true;
		},

        close() {
			this.dialogVisible = false;
        }
    }

}
</script>

<style>

.el-dialog{
    position: absolute;
    width: 1px;
    height: 1px;
 
    top: -300px;
    left: 0px; 
}

.dialogA {
    position: absolute;
	top: 400px;
	left: 1770px;
	width: 300px;
	height: 108px;

	border-radius:16px;
	padding: 15px 20px;
	text-align: left;
	font-size: 16px;
	color: rgb(255, 255, 255, 0.8);	
	background-color: rgb(0, 0, 0, 0.7);	
}

.dialogB {
    position: absolute;
	top: 8px;
	left: 8px;
	width: 284px;
	height: 92px;
	border-radius:12px;
	padding: 15px 20px;	
    border: 1px solid #333;
}



</style>