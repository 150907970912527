<template>

	<div class="module">
		<div class="caption">
			<p>事项流程</p>
		</div>		

		<div class="tab">
			<ol>
				<li>
					<div index=0 class="currentlabel" caption="公司注册流程">
						公司注册流程
					</div>
				</li>
				<li>
					<div index=1 class="label" caption="厂房租赁流程">
						厂房租赁流程
					</div>
				</li>
				<li>
					<div index=2 class="label" caption="实地自建流程">
						实地自建流程
					</div>
				</li>
				<li>
					<div index=3 class="label" caption="税收优惠流程">
						税收优惠流程
					</div>
				</li>
			</ol>

			<div class="pan_border">	
				
				<div class="caption2">
					<p>{{nowlabel}}</p>
				</div>

				<div class="pic">
					<ul>
						<li>
							<img src="~@/assets/images/qwys/sxlc_tab1.png" alt="">
						</li>
						<li>
							<img src="~@/assets/images/qwys/sxlc_tab2.png" alt="">
						</li>
						<li>
							<img src="~@/assets/images/qwys/sxlc_tab3.png" alt="">
						</li>
						<li>
							<img src="~@/assets/images/qwys/sxlc_tab4.png" alt="">
						</li>
					</ul>
				</div>
				
			</div>

		</div>



	</div>
  
</template>



<script>

export default {

    data() 
	{ 
		return { 
			timer: null,		// 定时器
			nowlabel: "公司注册流程",
		} 	
	}, 
	
    mounted() 
	{ 
		this.init() 
		this.timer = setInterval(() => {
       		setTimeout(this.nextpic(), 0)	   
   		}, 1000*10)
	},

	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {
			var that = this;
			
			var pic = document.querySelector('.tab .pic');
			// var picWidth = pic.offsetWidth;
			var picWidth = 471;
			
			var ul = document.querySelector('.tab .pic ul');
			var labeltabs = document.querySelectorAll(".tab ol li div")
			for (var i = 0; i<labeltabs.length; i++) {
				labeltabs[i].addEventListener('click', function() {
					
					for (var j = 0; j < labeltabs.length; j++) {
						labeltabs[j].className = 'label';
					}
					this.className = 'currentlabel';

					var index = this.getAttribute('index');            
					// animate(ul, -index * picWidth);            
					ul.style.left = -index * picWidth + 'px';

					that.nowlabel = this.getAttribute('caption');

				})
			}
		},
		
		nextpic() {        
			var labeltabs = document.querySelectorAll(".tab ol li div")
			for (var i = 0; i<labeltabs.length; i++) {
				if (labeltabs[i].className === 'currentlabel') {
					var next = i+1;
					if (next > 3)
					{
						next = 0;
					}

					labeltabs[next].click();
					break;
				}
			}
		}
	}

}

</script>


<style scoped>

.tab ol {
	margin: 18px 0 0 0;
	padding: 10px;
	left: 0px;
	position: absolute;	
}

.tab ol li {
	float: left;
	cursor: pointer;
}


.tab ol li .label {
	
	margin: 8px;
	width: 248px;
	height: 50px;
	
	font-size: 16px;
	line-height: 50px;
	border-radius:25px;
	color: rgb(255, 255, 255, 0.8);
    border: 1px solid #9BF5ED;
}

.tab ol li .currentlabel {
	
	margin: 8px;
	width: 248px;
	height: 50px;
	
	font-size: 16px;
	line-height: 50px;
	border-radius:25px;
	color: #0E2235;    
	background: #9BF5ED;
    border: 1px solid #9BF5ED;
	
}
	

.pan_border {	
	top: 210px;
	left: 20px;
	width: 514px;
	height: 616px;
	position: absolute;		
	border-radius:16px;
	background-color: #152a3a;	  
}

.tab .pic {
	top: 30px;
	left: 15px;
	width: 471px;
	height: 541px;
	position: relative;		
	/* background-color: #ac3362;	  */
	
	overflow: hidden;	
}

.tab .pic ul {
	
	margin: 0;
	padding: 0px;
	left: 0px;
    width: 400%;
    position: absolute;
}

.tab .pic ul li {
	
	float: left;
}

</style>