<template>

	<div class="module">
		<div class="caption">
			<p>环境指数</p>
		</div>

		<div class="info">
			<ul>
				<li>
					<b>{{HjzsData.hj1}}</b>
					<p><span class="color_1">■ </span>优</p>						
                    <div class="hr"></div>
				</li>
				<li>
					<b>{{HjzsData.hj2}}</b>
					<p><span class="color_2">■ </span>良</p>	
                    <div class="hr"></div>
				</li>
				<li>
					<b>{{HjzsData.hj3}}</b>
					<p><span class="color_3">■ </span>轻度污染</p>	
                    <div class="hr"></div>
				</li>
				<li>
					<b>{{HjzsData.hj4}}</b>
					<p><span class="color_4">■ </span>重度以上污染</p>	
				</li>
			</ul>
		</div>

		<div class="detail">	
			<div class="caption2">
				<p>污染物排放信息（单位：μg／m³）</p>
			</div>

			<div class="table">
                <div class="hr2"></div>					
                <div class="hr3_1"></div>				
                <div class="hr3_2"></div>				
                <div class="hr3_3"></div>				
                <div class="hr3_4"></div>				
                <div class="hr3_5"></div>
				<ul>
					<li>
						PM10
					</li>
					<li>
						PM2.5
					</li>
					<li>
						NO<span>2</span>						
					</li>
					<li>
						SO<span>2</span>	
					</li>
					<li>
						O<span>2</span>	
					</li>
					<li>
						CO
					</li>
				</ul>
				<ul>
					<li>
						<p class="tablevalue">{{HjzsData.pm10}}</p>
					</li>
					<li>
						<p class="tablevalue">{{HjzsData.pm25}}</p>
					</li>
					<li>
						<p class="tablevalue">{{HjzsData.no2}}</p>
					</li>
					<li>
						<p class="tablevalue">{{HjzsData.so2}}</p>
					</li>
					<li>
						<p class="tablevalue">{{HjzsData.o2}}</p>
					</li>						
					<li>
						<p class="tablevalue">{{HjzsData.co}}</p>						
					</li>
				</ul>

			</div>

			<div class="remark">
				<p>说明：<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;污染物排放量指污染源排入环境或其它设施的某种污染物的数量。污染源排入环境或其它设施的某种污染物的数量。对指定污染物，污染物排放量应是污染物产生量与污染物削减量之差，它是总量控制或排污许可证中进行污染源排污控制管理的指标之一。</p>
			</div>

		</div>


	</div>
  
</template>



<script>
import { getHjzs } from '../../network/qwys';


export default {

	data() {
		return {
			timer: null,		// 定时器
			HjzsData: {},		
		};
	},

	mounted() {
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {
			this.refresh();
		},

		refresh() {	

			getHjzs().then(res=>{
				this.HjzsData = res.data;
				// console.log("HjzsData: " + this.HjzsData);

			})
		}
	}

}
</script>


<style scoped>

.info ul {
	margin: 0;
	padding: 14px;
	left: 0px;
}
.info li {
	margin: 18px 0 18px 0;
	float: left;
	left: 32px;
	width: 125px;	
  	position:relative;
  	text-align: left;
}
.info li b {
	color: rgb(255, 255, 255, 1);
  	font-size: 22px;
	font-style: oblique;
}
.info li p {
	color: rgb(255, 255, 255, 0.8);
  	font-size: 12px;
  	line-height: 20px;
}

.hr{	
	top: 20px;
	left: 10px;	
	height: 20px;
	width: 100px;
	position: absolute;		
	border-right: 1px solid rgb(255, 255, 255, 0.4);
}

.color_1{	
	color: #65FF94;
}
.color_2{	
	color: #65F5FF;
}
.color_3{	
	color: #FFB065;
}
.color_4{	
	color: #FF4545;
}


.detail {	
	top: 140px;
	left: 20px;
	width: 514px;
	height: 260px;
	position: absolute;		
	border-radius:16px;
	background-color: #152a3a;		 	  
}


.table {
	top: 18px;
	margin: 0;
	padding: 0px;
	left: 15px;
	height: 94px;
	width: 480px;
	border-radius:10px;
  	position:relative;
    overflow: hidden;
    border: 1px solid rgb(255, 255, 255, 0.3);
}

.table span {	
  	font-size: 12px;
  	line-height: 24px;
}

.detail ul {
	margin: 0;
	padding: 0px;
	left: 0px;
	width: 100%;	
}
.detail li {
	margin: 14px 0 8px 0;
	float: left;	
	top: 0px;
	left: 0px;
	width: 80px;	
	height: 30px;
  	position:relative;
  	text-align: center;
	color: rgb(255, 255, 255, 0.8);
}

.tablevalue {
	color: rgb(255, 255, 255, 0.8);
  	line-height: 16px;
}


.hr2{	
	top: 47px;
	left: 0px;	
	height: 0px;
	width: 500px;
	position: absolute;		
	border-bottom: 1px solid rgb(255, 255, 255, 0.1);
}

.hr3_1{	
	top: 0px;
	left: 0px;	
	height: 100px;
	width: 80px;
	position: absolute;		
	border-right: 1px solid rgb(255, 255, 255, 0.1);
}
.hr3_2{	
	top: 0px;
	left: 0px;	
	height: 100px;
	width: 160px;
	position: absolute;		
	border-right: 1px solid rgb(255, 255, 255, 0.1);
}
.hr3_3{	
	top: 0px;
	left: 0px;	
	height: 100px;
	width: 240px;
	position: absolute;		
	border-right: 1px solid rgb(255, 255, 255, 0.1);
}
.hr3_4{	
	top: 0px;
	left: 0px;	
	height: 100px;
	width: 320px;
	position: absolute;		
	border-right: 1px solid rgb(255, 255, 255, 0.1);
}
.hr3_5{	
	top: 0px;
	left: 0px;	
	height: 100px;
	width: 400px;
	position: absolute;		
	border-right: 1px solid rgb(255, 255, 255, 0.1);
}

.remark {	
	top: 26px;
	left: 15px;
	height: 120px;
	width: 480px;
  	position:relative;
  	text-align: left;
    overflow: hidden;
  	font-size: 14px;
  	line-height: 24px;
	color: rgb(255, 255, 255, 0.8);
    /* border: 1px solid rgb(255, 255, 255, 0.3); */
}

</style>