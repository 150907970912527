<template>

	<div class="module">
		<div class="caption">
			<p>客运概况</p>
		</div>

		<div class="pan_line1">	
			<div class="capt">
				<p>莆田站</p>
			</div>

			<div class="info">
				<ul>
					<li>
						<b>{{ptData.fcbc}} <span>趟</span></b>
						<p><span class="color_1">■ </span>发车班次</p>						
						<div class="hr"></div>
					</li>
					<li>
						<b>{{ptData.kll}} <span>人次</span></b>
						<p><span class="color_1">■ </span>客流量</p>	
					</li>
				</ul>
			</div>
		</div>

		<div class="pan_line2">	
			<div class="capt">
				<p>涵江站</p>
			</div>

			<div class="info">
				<ul>
					<li>
						<b>{{hjData.fcbc}} <span>趟</span></b>
						<p><span class="color_2">■ </span>发车班次</p>						
						<div class="hr"></div>
					</li>
					<li>
						<b>{{hjData.kll}} <span>人次</span></b>
						<p><span class="color_2">■ </span>客流量</p>	
					</li>
				</ul>
			</div>
		</div>

	</div>
  
</template>


<script>
import { getKygk } from '../../network/qwys';

export default {

	data() {
		return {
			timer: null,		// 定时器
			ptData: {},		
			hjData: {},		
		};
	},

	mounted() {
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {
			this.refresh();
		},

		refresh() {	
			getKygk().then(res=>{
				this.ptData = res.data[0];
				this.hjData = res.data[1];
			})
		}
	}

}
</script>


<style scoped>

.pan_line1 {	
	top: 60px;
	left: 20px;
	width: 514px;
	height: 87px;
	position: absolute;		
	border-radius: 16px;
	background-color: #152a3a;	  
}

.pan_line1 .capt {	
	top: 28px;
	left: 20px;
	width: 100px;
	height: 36px;
	text-align: center;
  	position: relative;	
	background: url("~@/assets/images/qwys/station1.png") no-repeat left; 
  	background-size: contain;
}

.pan_line2 {	
	top: 165px;
	left: 20px;
	width: 514px;
	height: 87px;
	position: absolute;		
	border-radius: 16px;
	background-color: #152a3a;		  
}

.pan_line2 .capt {	
	top: 28px;
	left: 20px;
	width: 100px;
	height: 36px;
	text-align: center;
  	position: relative;	
	background: url("~@/assets/images/qwys/station2.png") no-repeat left; 
  	background-size: contain;
}


.capt p {	
	left: 46px;
	color: #F1F1F1;
  	font-size: 20px;
  	line-height: 38px;
  	text-align: left;
  	position: relative;	
}

.info {	
	top: -45px;
	left: 145px;
  	position: relative;
}

.info ul {
	margin: 0;
	padding: 14px;
	left: 0px;
}
.info li {
	margin: 18px 0 18px 0;
	float: left;
	left: 32px;
	width: 150px;	
  	position:relative;
  	text-align: left;
}
.info li b {
	color: rgb(255, 255, 255, 0.8);
  	font-size: 22px;
	font-style: oblique;
}
.info li b span {
  	font-size: 16px;
}
.info li p {
	color: rgb(255, 255, 255, 0.8);
  	font-size: 12px;
  	line-height: 34px;
}

.hr{
	top: 20px;
	left: 10px;	
	height: 20px;
	width: 100px;
	position: absolute;		
	border-right: 1px solid rgb(255, 255, 255, 0.8);
}

.color_1{	
	color: #65F5FF;
}
.color_2{	
	color: #65FF94;
}


</style>