import {request} from './request';


// 环境指数
export function getHjzs(){
  return request({
    url:'/api/v1/qwys/gethjzs'
  })
}

// 水质监测
export function getSzjc(){
  return request({
    url:'/api/v1/qwys/getszjc'
  })
}

// 客运概况
export function getKygk(){
  return request({
    url:'/api/v1/qwys/getkygk'
  })
}

// 运输流量
export function getYsllzt(){
  return request({
    url:'/api/v1/qwys/getysllzt'
  })
}
export function getYslllx(){
  return request({
    url:'/api/v1/qwys/getyslllx'
  })
}

// 交通工具浏览
export function getJtgjll(){
  return request({
    url:'/api/v1/qwys/getjtgjll'
  })
}

// 天气
export function getWeather(){
  return request({
    url:'/api/v1/qwys/getweather'
  })
}

