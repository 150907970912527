<template>

	<div class="module">
		<div class="caption">
			<p>交通工具流量</p>
		</div>

		<div class="pan_line1">	
			<div class="pan_chart" ref="chart">
			</div>
		</div>
	</div>
  
</template>



<script>
import * as Echarts from 'echarts';
import { getJtgjll } from '../../network/qwys';

export default {

    data() 
	{ 
		return { 
			timer: null,		// 定时器
			chart: null,
			option: null
		} 	
	}, 

    mounted() 
	{ 
		this.init() 
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {
			//1.初始化
			this.chart = Echarts.init(this.$refs.chart);
			
			
			//2.配置数据
			this.option = {
				// title: {
				// 	text: "交通工具流量",
				// 	left: "5%",
				// 	top: "5%",
				// 	textStyle: {
				// 		fontSize: 16,
				// 		color: "#fff"
				// 	}
				// },
				grid: {
					left: "2%",
					right: "3%",
					top: "18%",
					bottom: "0%",
					containLabel: !0
				},
				tooltip: {
					show: true,
					trigger: "item"
				},
				legend: {
					show: true,				
					right: "5%",
					y: "5",
					// icon: "stack",
					itemWidth: 20,
					itemHeight: 10,
					textStyle: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
					data: ["小型车", "中型车", "大型车"]
				},
				xAxis: [{
					type: "category",
					boundaryGap: !1,
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
					data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
				}],
				yAxis: [{
					name: "单位：辆",
					nameTextStyle: {
						padding: [0, 0, 0, 0],
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 15
					},			
					type: "value",				
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					}
				}],
				series: [{
					name: "小型车",
					type: "line",
					stack: "总量",
					symbol: "circle",
					symbolSize: 5,
					itemStyle: {
						normal: {
							color: "#FF6437",
							lineStyle: {
								color: "#FF6437",
								width: 1
							}
						}
					},
					markPoint: {
						itemStyle: {
							normal: {
								color: "#FF6437"
							}
						}
					},
					data: null
					// data: [20, 38, 40, 50, 90, 60, 50, 40, 20, 36, 33, 23]
				},
				{
					name: "中型车",
					type: "line",
					stack: "总量",
					symbol: "circle",
					symbolSize: 5,
					itemStyle: {
						normal: {
							color: "#68DBFF",
							lineStyle: {
								color: "#68DBFF",
								width: 1
							}
						}
					},
					data: null
					// data: [98, 99, 80, 86, 87, 77, 76, 80, 78, 89, 76, 89]
				},
				{
					name: "大型车",
					type: "line",
					stack: "总量",
					symbol: "circle",
					symbolSize: 5,
					itemStyle: {
						normal: {
							color: "#FFAE42",
							lineStyle: {
								color: "#FFAE42",
								width: 1
							}
						}
					},
					data: null
					// data: [82, 80, 61, 50, 40, 66, 67, 78, 79, 68, 66, 20]
				}]
			};

			// 3.传入数据
			this.chart.setOption(this.option);
			
			this.refresh();
		},
		
		refresh() {	

			getJtgjll().then(res=>{
				this.option.series[0].data = [res.data[0].xxc, res.data[1].xxc, res.data[2].xxc, res.data[3].xxc, res.data[4].xxc, res.data[5].xxc, 
											   res.data[6].xxc, res.data[7].xxc, res.data[8].xxc, res.data[9].xxc, res.data[10].xxc, res.data[11].xxc];
				this.option.series[1].data = [res.data[0].zxc, res.data[1].zxc, res.data[2].zxc, res.data[3].zxc, res.data[4].zxc, res.data[5].zxc, 
											   res.data[6].zxc, res.data[7].zxc, res.data[8].zxc, res.data[9].zxc, res.data[10].zxc, res.data[11].zxc];
				this.option.series[2].data = [res.data[0].dxc, res.data[1].dxc, res.data[2].dxc, res.data[3].dxc, res.data[4].dxc, res.data[5].dxc, 
											   res.data[6].dxc, res.data[7].dxc, res.data[8].dxc, res.data[9].dxc, res.data[10].dxc, res.data[11].dxc];

				this.chart.clear();
				this.chart.setOption(this.option);
			})
		}
	}

}
</script>


<style scoped>

.pan_line1 {	
	top: 50px;
	left: 20px;
	width: 514px;
	height: 205px;
	position: absolute;		
	border-radius:16px;
	background-color: #152a3a;	  
}

.pan_chart {	
	width: 100%;
	height: 100%;
}

</style>