<template>

	<div class="module">
		<div class="caption">
			<p>空气指数</p>
		</div>

		<div class="pan">				
			<ul>
				<li>			
					<div class="pan_chart" ref="chart1">
					</div>
					<p>湿度</p>
				</li>
				<li>					
					<div class="pan_chart" ref="chart2">
					</div>
					<p>气压</p>
				</li>
			</ul>
		</div>
	</div>
  
</template>



<script>
import * as Echarts from 'echarts';
import { getWeather } from '../../network/qwys';

export default {
    data() 
	{ 
		return { 
			timer: null,		// 定时器

			chart1: null,
			option1: null,

			chart2: null,
			option2: null
		} 	
	}, 
    mounted() 
	{ 
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {

		init() {
			//1.初始化
			this.chart1 = Echarts.init(this.$refs.chart1);
			this.chart2 = Echarts.init(this.$refs.chart2);	
			
			//2.配置数据
			this.option1 = {
				series: [{
						type: 'gauge',
						center: ["50%", "60%"],
						startAngle: 220,
						endAngle: -40,
						min: 0,
						max: 100,
						splitNumber: 10,
						itemStyle: {
							color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: "#00CCF7"
							},
							{
								offset: 1,
								color: "#93FFE5"
							}], 0),
						},
						progress: {
							show: true,
            				roundCap: true,
							width: 15
						},
						pointer: {
							show: false,
						},
						axisLine: {
            				roundCap: true,
							lineStyle: {
								width: 15
							}
						},
						axisTick: {
							distance: -30,
							splitNumber: 5,
							lineStyle: {
								width: 0,
								color: '#999'
							}
						},
						splitLine: {
							distance: 3,
							length: 5,
							lineStyle: {
								width: 3,
								color: '#999'								
							}
						},
						axisLabel: {
							distance: -35,
							color: 'rgba(255, 255, 255, 0.8)',
							fontSize: 14
						},
						anchor: {
							show: false
						},
						title: {
							show: false
						},
						detail: {
							valueAnimation: true,
							width: '60%',
							lineHeight: 20,
							height: '15%',
							borderRadius: 8,
							offsetCenter: [0, '-5%'],
							fontSize: 20,					
							// fontWeight: 'bolder',
							formatter: '{value}\n(%)',
							color: 'rgba(255, 255, 255, 0.8)',
						},
						data: null
						// data: [ 51 ]
						// data: [{
						// 	value: 51
						// }]
					}
				],
			};
			
			this.option2 = {
				series: [{
						type: 'gauge',
						center: ["50%", "60%"],
						startAngle: 220,
						endAngle: -40,
						min: 0,
						max: 1600,
						splitNumber: 10,
						itemStyle: {
							color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: "#00CCF7"
							},
							{
								offset: 1,
								color: "#93FFE5"
							}], 0),
						},
						progress: {
							show: true,
            				roundCap: true,
							width: 15
						},

						pointer: {
							show: false,
						},
						axisLine: {
            				roundCap: true,
							lineStyle: {
								width: 15
							}
						},
						axisTick: {
							distance: -30,
							splitNumber: 5,
							lineStyle: {
								width: 0,
								color: '#999'
							}
						},
						splitLine: {
							distance: 3,
							length: 5,
							lineStyle: {
								width: 3,
								color: '#999'
							}
						},
						axisLabel: {
							distance: -45,
							color: 'rgba(255, 255, 255, 0.8)',
							fontSize: 14
						},
						anchor: {
							show: false
						},
						title: {
							show: false
						},
						detail: {
							valueAnimation: true,
							width: '60%',
							lineHeight: 20,
							height: '15%',
							borderRadius: 8,
							offsetCenter: [0, '-5%'],
							fontSize: 20,
							formatter: '{value}\n(hPa)',
							color: 'rgba(255, 255, 255, 0.8)',
						},
						data: null
						// data: [ 100.56 ]
						// data: [{
						// 	value: 100.56
						// }]
					}
				],
			};


			// 3.传入数据
			this.chart1.setOption(this.option1);
			this.chart2.setOption(this.option2);
			
			this.refresh();
		},

		refresh() {			

			getWeather().then(res=>{
				// this.$store.commit('setWeather', res)

				this.option1.series[0].data = [res.data.data[0].humidity.replace('%', '')];
				this.chart1.clear();
				this.chart1.setOption(this.option1);
				
				this.option2.series[0].data = [res.data.data[0].pressure];
				this.chart2.clear();
				this.chart2.setOption(this.option2);
			})	


		}
	}
}
</script>



<style scoped>

.pan {	
	top: 3px;
	left: 10px;
	width: 534px;
	height: 204px;
  	position: relative;
}

.pan ul {
	margin: 0;
	padding: 14px;
	left: 0px;

}
.pan li {
	float: left;
	width: 250px;
	height: 204px;
}

.pan li p {	
	top: -27px;
	color: rgb(255, 255, 255, 0.8);
  	font-size: 16px;
  	line-height: 16px;
  	position: relative;
}

.pan_chart {	
	width: 100%;
	height: 100%;
}


</style>